<script lang="ts">import showdown from 'showdown';
import { onMount } from 'svelte';
export let content;
let markdownContainer;
// Custom extension for header IDs - processes the markdown before conversion
const headerIdsExtension = {
    type: 'lang',
    regex: /^(#{1,6})\s+(.*?)\s*{#([^}]+)}/gm,
    replace: function (match, hashes, title, id) {
        return `${hashes} ${title} {id="${id}"}`;
    }
};
// Extension to convert id attributes to actual IDs after HTML conversion
const idExtension = {
    type: 'output',
    regex: /<h([1-6])[^>]*{id="([^"]+)"}[^>]*>(.*?)<\/h\1>/g,
    replace: '<h$1 id="$2">$3</h$1>'
};
const converter = new showdown.Converter({
    tables: true,
    tasklists: true,
    strikethrough: true,
    emoji: true,
    noHeaderId: false,
    ghCompatibleHeaderId: false,
    headerLevelStart: 1,
    parseImgDimensions: true,
    simplifiedAutoLink: true,
    literalMidWordUnderscores: true,
    ghCodeBlocks: true,
    smoothLivePreview: true,
    extensions: [headerIdsExtension, idExtension]
});
$: html = converter.makeHtml(content);
function scrollToElement(elementId) {
    console.log('Attempting to scroll to:', elementId);
    const element = markdownContainer.querySelector(`#${elementId}`);
    console.log('Found element:', element);
    if (element) {
        try {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
        catch (error) {
            console.error('Scroll error:', error);
        }
    }
}
function handleClick(event) {
    const target = event.target;
    const linkElement = target.closest('a');
    if (!linkElement)
        return;
    const href = linkElement.getAttribute('href');
    if (href === null || href === void 0 ? void 0 : href.startsWith('#')) {
        event.preventDefault();
        const elementId = href.slice(1);
        scrollToElement(elementId);
    }
}
onMount(() => {
    if (!markdownContainer)
        return;
    // Log headers and their IDs for debugging
    const headers = markdownContainer.querySelectorAll('h1, h2, h3, h4, h5, h6');
    console.log('Found headers:', headers.length);
    headers.forEach(header => {
        console.log(`${header.tagName} "${header.textContent}" id="${header.id}"`);
    });
});
</script>

<div 
    bind:this={markdownContainer}
    class="markdown-content"
    on:click={handleClick}
>
    {@html html}
</div>

<style>
    .markdown-content {
        max-height: 70vh;
        overflow-y: auto;
        padding: 1rem;
    }

    .markdown-content :global(h1),
    .markdown-content :global(h2),
    .markdown-content :global(h3),
    .markdown-content :global(h4),
    .markdown-content :global(h5),
    .markdown-content :global(h6) {
        margin-top: 1em;
        margin-bottom: 0.5em;
        font-weight: 600;
        line-height: 1.25;
        scroll-margin-top: 1em;
    }

    .markdown-content :global(h1) {
        font-size: 2em;
        padding-bottom: 0.3em;
        border-bottom: 1px solid #eaecef;
    }

    .markdown-content :global(h2) {
        font-size: 1.5em;
        padding-bottom: 0.3em;
        border-bottom: 1px solid #eaecef;
    }

    .markdown-content :global(h3) { font-size: 1.25em; }
    .markdown-content :global(h4) { font-size: 1em; }
    .markdown-content :global(h5) { font-size: 0.875em; }
    .markdown-content :global(h6) { 
        font-size: 0.85em;
        color: #6a737d;
    }

    .markdown-content :global(p) {
        margin-top: 0;
        margin-bottom: 1em;
    }

    .markdown-content :global(ul),
    .markdown-content :global(ol) {
        margin-top: 0;
        margin-bottom: 1em;
        padding-left: 2em;
    }

    .markdown-content :global(li) {
        margin: 0.25em 0;
    }

    .markdown-content :global(code) {
        padding: 0.2em 0.4em;
        margin: 0;
        font-size: 85%;
        background-color: rgba(27, 31, 35, 0.05);
        border-radius: 3px;
    }

    .markdown-content :global(pre) {
        padding: 16px;
        overflow: auto;
        font-size: 85%;
        line-height: 1.45;
        background-color: #f6f8fa;
        border-radius: 3px;
    }

    .markdown-content :global(pre code) {
        padding: 0;
        margin: 0;
        background-color: transparent;
    }

    .markdown-content :global(a) {
        color: #0366d6;
        text-decoration: none;
    }

    .markdown-content :global(a:hover) {
        text-decoration: underline;
    }

    .markdown-content :global(table) {
        border-spacing: 0;
        border-collapse: collapse;
        margin-top: 0;
        margin-bottom: 16px;
    }

    .markdown-content :global(td),
    .markdown-content :global(th) {
        padding: 6px 13px;
        border: 1px solid #dfe2e5;
    }

    .markdown-content :global(tr:nth-child(2n)) {
        background-color: #f6f8fa;
    }

</style>
