import axios from "axios";
import EventEmitter from "events";
import Login from "src/components/Login.svelte";
import { tick } from "svelte";
import { navigate } from "svelte-routing";
import { derived, get, writable } from "svelte/store";

interface authorizer_reponse {
	access_token: string;
	refresh_token: string;
	refresh_token_expires_in: number;
	expiration_interval: "seconds";
	token_type: "bearer";
}

interface AUTH_TOKEN_PAYLOAD {
	user: {
		id: number;
		username: string;
		preferences: {};
		roles: { id: number; name: string }[];
	};
	type: "access";
	iat: 1701380967;
	exp: 1700383567;
}

interface REFRESH_TOKEN_PAYLOAD {
	user_id: number;
	type: "refresh";
	iat: 1701383555;
	exp: 1700388955;
}

const LATENCY_ALLOWANCE_SECONDS = 5;

enum LS_NAMING {
	AUTH_TOKEN = "_APP__AUTH_TOKEN",
	REFRESH_TOKEN = "_APP__REFRESH_TOKEN"
}

const TOOLS = {
	// AUTH
	GET_AUTH_TOKEN: () => {
		return parseOrNull(localStorage.getItem(LS_NAMING.AUTH_TOKEN)) as string | null;
	},
	SET_AUTH_TOKEN: (token: string) => {
		localStorage.setItem(LS_NAMING.AUTH_TOKEN, JSON.stringify(token));
		AUTH_TOKEN.set(token);
	},
	PARSE_AUTH_TOKEN: () => JSON.parse(atob(TOOLS.GET_AUTH_TOKEN().split(".")[1])) as AUTH_TOKEN_PAYLOAD,
	IS_AUTH_TOKEN_VALID: () =>
		TOOLS.GET_AUTH_TOKEN() ? TOOLS.PARSE_AUTH_TOKEN().exp * 1000 - LATENCY_ALLOWANCE_SECONDS * 1000 > Date.now() : false,

	// REFRESH
	GET_REFRESH_TOKEN: () => {
		return parseOrNull(localStorage.getItem(LS_NAMING.REFRESH_TOKEN)) as string | null;
	},
	SET_REFRESH_TOKEN: (token: string) => {
		localStorage.setItem(LS_NAMING.REFRESH_TOKEN, JSON.stringify(token));
	},
	PARSE_REFRESH_TOKEN: () => JSON.parse(atob(TOOLS.GET_REFRESH_TOKEN().split(".")[1])) as REFRESH_TOKEN_PAYLOAD,

	IS_REFRESH_TOKEN_VALID: () => TOOLS.PARSE_REFRESH_TOKEN().exp * 1000 - LATENCY_ALLOWANCE_SECONDS * 1000 > Date.now()
};

axios.interceptors.request.use(function (config) {
	return new Promise(function (r) {
		function resolve() {
			config.headers ||= {};
			config.headers["Authorization"] ||= `Bearer ${TOOLS.GET_AUTH_TOKEN()}`;
			r(config);
		}
		if (config.ignore_token_check || TOOLS.IS_AUTH_TOKEN_VALID()) {
			resolve();
		} else {
			AUTH_EVENTS.once("token_valid", () => {
				resolve();
			});
			AUTH_EVENTS.emit("refresh_token");
		}
	});
});

makeGlobals({
	axios
});

export const AUTH_ROLES = (() => {
	const store = writable<string[]>(parseOrNull(localStorage["_APP__AUTH_ROLES"]));
	return {
		...store,
		set(value: (string | { name: string })[]) {
			const roles: string[] = [];

			if (Array.isNotEmpty(value)) {
				for (const role of value) {
					if (typeof role === "string") {
						roles.push(role);
					} else if (typeof role === "object" && role !== null) {
						roles.push(role.name);
					} else {
						throw new Error("Wrong role type");
					}
				}
			}

			return Array.isNotEmpty(roles) ? store.set(roles) : store.set(null);
		},
		hasRole(role) {
			return lib.getStoreValue(this).includes(role);
		},
		hasOneOfRoles(roles) {
			for (const role of lib.getStoreValue(this)) {
				if (roles.includes(role)) {
					return true;
				}
			}

			return false;
		},
		hasAllRoles(roles) {
			const userRoles = lib.getStoreValue(this);
			return !roles.map((role) => userRoles.includes(role)).includes(false);
		}
	};
})();

export const AUTH_TOKEN = writable<string>(TOOLS.GET_AUTH_TOKEN());

export const USER = writable<TschemaData_account>(parseOrNull(localStorage["_APP__USER"]));

export const ROUTES = writable<PAGE_ROUTE>(null);

export interface AppConfigOpts {
	default_formatters: { [key in TschemaTableName]?: string };
	PageBuilder: {
		schema: stringOf;
		actions?: DeepPartial<{
			http_request: {
				defaults: {
					timeout: number;
					response_callbacks: {
						[key in "Local Error" | "200" | "400" | "500" | string]: PB.PageView__Action[];
					};
				};
			};
		}>;
	};
	theme: {
		appPrimaryThemeColor: string;
	};
	logo_url: string;
	favicon_url: string;
	datetime_defaults: {
		date: string;
		time: string;
		datetime: string;
	};
	user_session_query?: string;
	delete_dialog: {
		default: true;
		debug: string[];
	} & {
		[key in "delete_cascade" | "delete_non_cascade"]: {
			require_confirmation_code: boolean;
			header_text: string;
			body_1_text: string;
			body_2_text?: string;
		};
	};
}

export const REAL_APP_CONFIG = writable<AppConfigOpts | null>(null);
export const APP_CONFIG: Readable<AppConfigOpts> = derived(REAL_APP_CONFIG, ($APP_CONFIG) => {
	function callback(value: any, path: (string | number)[]) {
		if (_.isObjectLike(value)) {
			if (Array.isArray(value)) {
				return value.map((item, index) => _.cloneWith(item, (v) => callback(v, [...path, index])));
			} else {
				return _.reduce(
					_.entries(value),
					(acc, [key, value]) => {
						acc[key] = callback(value, [...path, key]);
						return acc;
					},
					{}
				);
			}
		} else if (typeof value === "string") {
			// all except PageBuilder and default_formatters
			if (["PageBuilder", "default_formatters"].includes(path[0] as any)) {
				return value;
			}
			if (value.includes("{{") && value.includes("}}")) {
				return lib.convertTemplate(value, $APP_CONFIG, {
					use_APP_CONFIG: false
				});
			} else {
				return value;
			}
		} else {
			return value;
		}
	}
	return _.cloneWith($APP_CONFIG, (value) => callback(value, []));
});

APP_CONFIG.set = REAL_APP_CONFIG.set;
APP_CONFIG.update = REAL_APP_CONFIG.update;

const unsubber = APP_CONFIG.subscribe((state) => {
	if (!state?.theme) return;
	setTimeout(() => {
		// delay so unsubber is available
		unsubber();
		const overrides = Object.entries(state.theme).filter(([key]) => key.startsWith("--"));
		const style = document.createElement("style");
		style.textContent = `* { ${overrides.map(([name, value]) => `${name}: ${value} !important;`).join(' ')} }`;
		document.body.appendChild(style.getRootNode());
	});
});

APP_CONFIG.subscribe((state) => {
	{
		let favicon = document.querySelector("link[rel=icon]");
		if (!favicon) {
			favicon = document.createElement("link");
			favicon.rel = "icon";
			favicon.href = state?.favicon_url || state?.logo_url;
			document.head.appendChild(favicon);
		} else {
			favicon.href = state?.favicon_url || state?.logo_url;
		}
	}
});

AUTH_ROLES.subscribe((state) => (localStorage["_APP__AUTH_ROLES"] = JSON.stringify(state)));
USER.subscribe((state) => (localStorage["_APP__USER"] = JSON.stringify(state)));

export const USER_SESSION_QUERY_MADE = writable(false);

export const AUTH_EVENTS = new EventEmitter();

AUTH_EVENTS.on("APP_CONFIG_FETCHED", () => {
	if (lib.getStoreValue(lib.APP_CONFIG).user_session_query) {
		lib
			.query(lib.getStoreValue(lib.APP_CONFIG).user_session_query, {
				variables: _.entries(lib.getStoreValue(lib.USER)).reduce((acc, [key, value]) => {
					acc[`user_${key}`] = value;
					return acc;
				}, {})
			})
			.then((res) => {
				lib.modStore(USER, (state) => {
					_.assign(state, res);
				});
				USER_SESSION_QUERY_MADE.set(true);
			});
	} else {
		USER_SESSION_QUERY_MADE.set(true);
	}
});

AUTH_EVENTS.on("login", function (data) {
	localStorage["_APP__AUTH_LAST_RESPONSE"] = JSON.stringify(data);

	localStorage.setItem("_APP__REFRESH_TOKEN", JSON.stringify(data.refresh_token));

	if (data.token_type === "bearer") {
		TOOLS.SET_AUTH_TOKEN(data.access_token);
	} else {
		throw new Error("Unimplemented token_type");
	}

	const parsed: {
		exp: number;
		iat: number;
		type: "access";
		user: TschemaData_account & { roles: { name: string }[] };
	} = JSON.parse(atob(data.access_token.split(".")[1]));

	AUTH_ROLES.set(parsed.user.roles.mapFromObjectKey("name"));
	USER.set(parsed.user);

	AUTH_EVENTS.emit("token_valid");
	AUTH_EVENTS.emit("setup_refresh_token");
});

AUTH_EVENTS.on("refresh_token", () => {
	AUTHORIZE.withRefreshToken();
});

let token_refresh_timeout_id: NodeJS.Timeout;
AUTH_EVENTS.on("setup_refresh_token", () => {
	clearTimeout(token_refresh_timeout_id);
	const refresh_in = TOOLS.PARSE_REFRESH_TOKEN().exp * 1000 - LATENCY_ALLOWANCE_SECONDS * 1000 - Date.now();

	token_refresh_timeout_id = setTimeout(() => {
		AUTH_EVENTS.emit("refresh_token");
	}, refresh_in);
});

if (!TOOLS.IS_AUTH_TOKEN_VALID()) {
	deauthorize();
}

function parseOrNull(str: string) {
	try {
		return JSON.parse(str);
	} catch (error) {
		return null;
	}
}

export function deauthorize() {
	// navigate('/')
	AUTH_TOKEN.set(null);
	AUTH_ROLES.set(null);
	USER.set(null);
	ROUTES.set(null);
	APP_CONFIG.set(null);
	localStorage.clear();
	loginDialog();
	USER_SESSION_QUERY_MADE.set(false);
}

export function isAuthed(roles: typeof AUTH_ROLES, required: string[], forbidden: string[]): boolean {
	if (roles.hasRole("admin")) {
		return true;
	}
	if (Array.isNotEmpty(required) && !roles.hasOneOfRoles(required)) {
		return false;
	}

	if (Array.isNotEmpty(forbidden) && roles.hasOneOfRoles(forbidden)) {
		return false;
	}

	return true;
}

export function PROTECTOR<T>(
	value: T,
	{
		requiredRoles = [],
		forbiddenRoles = [],
		unAuthValue = undefined
	}: {
		requiredRoles?: string[];
		forbiddenRoles?: string[];
		unAuthValue?: T;
	}
): T {
	if (isAuthed(AUTH_ROLES, requiredRoles, forbiddenRoles)) {
		return value;
	} else {
		if (typeof unAuthValue !== "undefined") {
			return unAuthValue;
		} else {
			if (Array.isArray(value)) {
				// @ts-ignore
				return [];
			} else {
				return null || { string: "", number: -1, bigint: -1, boolean: false }[typeof value];
			}
		}
	}
}

export const AUTHORIZE = {
	async withPassword(creds: { username: string; password: string }) {
		try {
			const { data } = await axios.post<authorizer_reponse>(
				window.PluginsManager.getEndpoint("authorizer") + "/api/v1/core/plugins/authorizer/login",
				{
					...creds,
					grant_type: "password"
				},
				{
					ignore_token_check: true
				}
			);

			AUTH_EVENTS.emit("login", data);
		} catch (error) {
			AUTH_EVENTS.emit("login_error", error);
		}
	},
	async withRefreshToken() {
		try {
			const { data } = await axios.post<authorizer_reponse>(
				window.PluginsManager.getEndpoint("authorizer") + "/api/v1/core/plugins/authorizer/login",
				{
					grant_type: "refresh"
				},
				{
					headers: {
						Authorization: `Bearer ${JSON.parse(localStorage.getItem("_APP__REFRESH_TOKEN"))}`
					},
					ignore_token_check: true
				}
			);

			AUTH_EVENTS.emit("login", data);
		} catch (error) {
			console.error(error);
			loginDialog({
				on_load() {
					AUTH_EVENTS.emit("login_error", "Your session has expired. Please login again");
				}
			});
		}
	}
};

export async function getAppConfig() {
	console.debug("getting App Config");
	if (svelte.internal.get_store_value(ROUTES) && svelte.internal.get_store_value(APP_CONFIG)) {
		console.log("cancel getAppConfig");
		return;
	}

	const {
		uizer_page: [NAV_Page]
	} = await lib.get2({
		tableName: "uizer_page",
		where: {
			uid: {
				eq: "NAV"
			}
		},
		fields: ["config"]
	});

	const {
		uizer_page: [CONFIG_Page]
	} = await lib.get2({
		tableName: "uizer_page",
		where: {
			uid: {
				eq: "APP_CONFIG"
			}
		},
		fields: [`name`, `uid`, `description`, `config`, `id`]
	});

	const app_config_raw: AppConfigOpts = JSON.parse(CONFIG_Page.config);

	{
		/**
		 * defaults for app config
		 */

		{
			if (!app_config_raw.default_formatters) {
				app_config_raw.default_formatters = {};
			} else {
				for (const table in app_config_raw.default_formatters) {
					if (["$:name", "$:id", "[$:id]"].includes(app_config_raw.default_formatters[table])) {
						console.log("You won't see this message if you save APP_CONFIG (you don't have to make any changes).");
						console.error(
							"Uizer removed a pointless default formatter for table: ",
							table,
							app_config_raw.default_formatters[table]
						);
						delete app_config_raw.default_formatters[table];
					}
				}
			}
		}

		{
			// delete dialog
			if (!app_config_raw.delete_dialog) {
				app_config_raw.delete_dialog = {
					default: true,
					debug: ["admin", "delete_debug"]
				};
			}

			for (const property_name of [`delete_cascade`, `delete_non_cascade`] as const) {
				if (!app_config_raw.delete_dialog[property_name]) {
					app_config_raw.delete_dialog[property_name] = {
						require_confirmation_code: property_name === "delete_cascade",
						header_text: "Delete {{ tableName_nice_name }}: {{ current_description }}",
						body_1_text: "Are you sure you want to delete this record?",
						body_2_text:
							"This may have unintended consequences. Only do this if if know what you are doing. Please contact your Manager if you are unsure."
					};
				}

				if (typeof app_config_raw.delete_dialog[property_name]["require_confirmation_code"] !== "boolean") {
					app_config_raw.delete_dialog[property_name]["require_confirmation_code"] = property_name === "delete_cascade";
				}
			}
		}
	}
	ROUTES.set(JSON.parse(NAV_Page.config));
	APP_CONFIG.set(app_config_raw);
	console.debug("emitting event");
	AUTH_EVENTS.emit("APP_CONFIG_FETCHED");

	return;
}

function loginDialog({ on_load = () => {} } = {}) {
	tick().then(() => {
		lib
			.dialog({
				component: Login,
				props: {
					bp_width: "sm",
					hide_close: true
				}
			})
			.then((dialog) => {
				typeof on_load === "function" && on_load();
				AUTH_EVENTS.on("token_valid", () => {
					dialog.$destroy();
				});
			});
	});
}
